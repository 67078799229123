<template>
  <div class="login-container">
    <div class="login-form">
      <div class="l-tab">
<!--        <span :class="tabListIndex === 0 ? 't-item-active1' : 't-item1'" @click="changetabList(0)">
          短信登陆
        </span> -->
          百牛课堂教师端
<!--        <span :class="tabListIndex === 2 ? 't-item-active3' : 't-item3'" @click="changetabList(2)">
          扫码登陆
        </span> -->
      </div>
<!--      <div class="login-form-wrap" v-if="tabListIndex === 0">
        <el-form label-width="0px" :model="loginForm2" :rules="loginRules2" ref="loginForm2">
          <el-form-item prop="phone">
            <el-input v-model="loginForm2.phone" placeholder="请输入登录手机号"></el-input>
          </el-form-item>
          <el-form-item prop="code" class="password-item">
            <el-input v-model="loginForm2.code" placeholder="请输入短信验证码"></el-input>
            <div class="l-forget" v-show="showTimer">重新发送({{ time }})</div>
            <div class="l-forget" v-show="!showTimer" @click="getCode('login', loginForm2.phone)">
              获取验证码
            </div>
          </el-form-item>
          <el-form-item>
            <el-button class="login-btn" type="primary" @click="onLogin2">登录</el-button>
          </el-form-item>
        </el-form>
        <div class="l-tips">
          登录即表示同意
          <span>用户协议</span>
          和
          <span>隐私政策</span>
        </div>
      </div> -->
      <div class="login-form-wrap">
        <el-form label-width="0px" :model="loginForm" :rules="loginRules" ref="loginForm">
          <el-form-item prop="phone">
            <el-input v-model="loginForm.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item prop="password" class="password-item">
            <el-input
              v-model="loginForm.password"
              placeholder="请输入密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="login-btn" type="primary" @click="onLogin">登录</el-button>
          </el-form-item>
        </el-form>
        <div class="l-text" @click="href('/find/password')">忘记密码？</div>
      </div>
<!--      <div class="login-form-wrap" v-if="tabListIndex === 2">
        <div class="QR"></div>
        <div class="l-title">请用某某某 App 扫码</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5';
export default {
  name: '',
  data() {
    return {
      tabListIndex: 0,
      time: 60,
      showTimer: false,
      branchId: '',
      defaultBranch: this.$.data.get('defaultBranch'),
      loginForm: {
        phone: '',
        password: ''
      },
      loginForm2: {
        phone: '',
        code: ''
      },
      loginRules: {
        phone: [
          { required: true, message: '请输入正确的手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确' }
        ],
        password: [
          {
            required: true,
            message: '请输入6-20位密码，区分大小写，不能使用空格！',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 20,
            message: '请输入6-20位密码，区分大小写，不能使用空格！',
            trigger: 'blur'
          }
        ]
      },
      loginRules2: {
        phone: [
          { required: true, message: '请输入正确的手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确' }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {},
  created() {
    this.getBranch();
  },
  mounted() {},
  watch: {},
  methods: {
    // 查询分校列表
    getBranch() {
      this.get('/biz/branch/list', {}, e => {
        if (e.code == 200) {
          this.branchList = e.data;
          console.log(this.branchList);
          if (!this.defaultBranch) {
            this.defaultBranch = e.data[0];
            this.branchId = this.defaultBranch.branchId;
            this.$.data.set('defaultBranch', this.defaultBranch);
          }
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 登录
    onLogin() {
      let branchId = this.branchId || '';
      if (!branchId || branchId === '') branchId = 1;
      const pwd = md5(this.loginForm.password);
      this.post(
        '/biz/teacher/login/pwd',
        {
          branchId: branchId,
          // pwd: this.loginForm.password,
          pwd: pwd,
          mobileNo: this.loginForm.phone
        },
        e => {
          if (e.code == 200) {
            this.$.ui.toast('登录成功');
            this.$.data.set('Token', e.data.token);
            this.$.data.set('userSig', e.data.userSig);
            this.$.data.set('userCode', e.data.userCode);
            this.href('/');
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    onLogin2() {}
  },
  components: {}
};
</script>

<style></style>
